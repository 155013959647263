import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SimplebarAngularModule } from 'simplebar-angular';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { VerticalComponent } from './vertical/vertical.component';
import { TranslateModule } from '@ngx-translate/core';
import { MainService } from '../core/services/main.service';
import { StatsService } from '../core/services/stats.service';
import { AISelectorComponent } from '../pages/components/ai-selector/ai-selector.component';
import { ApiService } from '../core/services/apiService';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutComponent,
    SidebarComponent,
    FooterComponent,
    VerticalComponent,
    AISelectorComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    UiSwitchModule
  ],
  providers: [
    MainService,
    StatsService,
    ApiService
  ]
})
export class LayoutsModule { }
