import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'Home',
        link: '/',
        img: "assets/img/svg/home.svg",
        imgWhite: "assets/img/svg/home-white.svg",
    },
    {
        icon: 'ft-layout',
        label: 'Dashboard',
        link: '/dashboard',
    },
    // {
    //     label: 'Strategy',
    //     link: '/strategy',
    //     img: "assets/img/svg/strategy.svg",
    //     imgWhite: "assets/img/svg/strategy-white.svg"
    // },
    {
        icon: 'ft-list',
        label: 'Process',
        link: '/process',
    },
    {
        icon: 'icon-layers',
        label: 'Application',
        link: '/application',
    },
    {
        label: 'Data',
        link: '/data',
        img: 'assets/img/svg/data.svg',
        imgWhite: 'assets/img/svg/data-white.svg'
    }
    // {
    //     label: 'Settings',
    //     link: '/settings',
    //     img: 'assets/img/svg/data.svg',
    //     imgWhite: 'assets/img/svg/data-white.svg'
    // }

];

