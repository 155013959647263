export class Architecture {

    public id: string;
    public name: string;
    public description: string;
    public technologies: Array<{
        id: string,
        name: string
    }>;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.technologies = json.technologies;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Architecture>;
            json.forEach(element => {
                ret.push(new Architecture(element));
            });
            return ret;
        }
    }
}