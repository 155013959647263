export class Diagram {

    public id: string;
    public name: string;
    public description: string;
    public fileType: string;
    public uriType: string;
    public diagramType: string;
    public uri: string;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.fileType = json.fileType;
        this.uriType = json.uriType;
        this.diagramType = json.diagramType;
        this.uri = json.uri;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Diagram>;
            json.forEach(element => {
                ret.push(new Diagram(element));
            });
            return ret;
        }
    }
}