import { Component, OnInit } from '@angular/core';
import { MainService } from './core/services/main.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private mainService: MainService) {

  }
  ngOnInit() {
    this.mainService.currentDevise = environment.devise.eur;
  }

}
