export const environment = {
  production: false,
  serverURL: "https://eaxplorer-backend-stg-67db11e79950.herokuapp.com",
  authDomain: "dev-1d4sm6zx1a1b172v.eu.auth0.com",
  authClientID: "SrcrK0Y0OI82zn42LR4LuYei5ZotZXYY",
  authAudience: "https://dev-1d4sm6zx1a1b172v.eu.auth0.com/api/v2/",
  version: "1.2.0",
  devise: {
    eur: {
      icon: "fa fa-eur"
    },
    usd: {
      usd: "fa-usd"
    }
  },
  aiExamples: [
    "What are the data exchanges of the application 'Order Tracking' (application source and target names, data's name, frequency, technology) ?",
    "What are the infrastructures deployed in Netherlands ?",
    "Which applications use the technology 'Tableau' ?"
  ]



};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
