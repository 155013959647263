export class Functionality {

    public id: string;
    public name: string;
    public description: string;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Functionality>;
            json.forEach(element => {
                ret.push(new Functionality(element));
            });
            return ret;
        }
    }
}