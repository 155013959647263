import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { LayoutComponent } from './layouts/layout.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { SimpleLayoutComponent } from './layouts/simple-layout/simple-layout.component';
const routes: Routes = [
  { path: 'content', component: SimpleLayoutComponent, loadChildren: () => import('./layouts/simple-layout/simple-layout-routing.module').then(m => m.SimplePagesRoutingModule), canActivate: [AuthGuard] },
  { path: 'lucid', component: SimpleLayoutComponent, loadChildren: () => import('./layouts/simple-layout/simple-layout-routing.module').then(m => m.SimplePagesRoutingModule), canActivate: [AuthGuard] },
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
