export class User {

    public user_id: string;
    public firstname: string;
    public lastname: string;
    public email: string;
    public admin: boolean;
    public hasLicense: boolean;

    constructor(json) {
        this.user_id = json.user_id;
        this.firstname = json.given_name;
        this.lastname = json.family_name;
        this.email = json.email;
        this.admin = json.user_metadata ? json.user_metadata.userRole == "admin" ? true : false : undefined;
        this.hasLicense = json.user_metadata ? json.user_metadata.hasLicense : undefined;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<User>;
            json.forEach(element => {
                ret.push(new User(element));
            });

            return ret;
        }
    }

}