import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ApiService {


    constructor(public http: HttpClient) {
    }



    public makePost(url, dataToSend) {
        return new Promise((resolve, reject) => {

            // create header
            let headers = new HttpHeaders();
            headers.set("Content-Type", "application/json");
            this.http.post(url, dataToSend, { headers: headers }).subscribe(
                data => {
                    resolve(data);
                },
                err => {
                    // handle errors
                    reject(err);
                });

        });
    }

    public makePatch(url, dataToSend) {
        return new Promise((resolve, reject) => {

            // create header
            let headers = new HttpHeaders();
            headers.set("Content-Type", "application/json");
            this.http.patch(url, dataToSend, { headers: headers }).subscribe(
                data => {
                    resolve(data);
                },
                err => {
                    // handle errors
                    reject(err);
                });

        });
    }

    public makeDelete(url) {
        return new Promise((resolve, reject) => {

            // create header
            let headers = new HttpHeaders();
            headers.set("Content-Type", "application/json");
            this.http.delete(url, { headers: headers }).subscribe(
                data => {
                    resolve(data);
                },
                err => {
                    // handle errors
                    reject(err);
                });

        });
    }


    public makeGet(url) {
        return new Promise((resolve, reject) => {

            // create header
            let headers = new HttpHeaders();
            headers.set("Content-Type", "application/json");
            this.http.get(url, { headers: headers }).subscribe(
                data => {
                    resolve(data);
                },
                err => {
                    // handle errors
                    reject(err);
                });

        });
    }


}