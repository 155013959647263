import { Application } from "./application";
import { Attachment } from "./attachment";
import { DataExchange } from "./dataExchange";
import { Diagram } from "./diagram";
import { Infrastructure } from "./infrastructure";

export class Data {

    public id: string;
    public name: string;
    public description: string;
    public attributes: [
        {
            label: string,
            value: string
        }
    ];
    public applicationStore: Array<Application>;
    public applicationExchanges: Array<DataExchange>;
    public infrastructures: Array<Infrastructure>;
    public diagrams: Array<Diagram>;
    public lastUpdate: string;
    public attachments: Array<Attachment>;
    public category: string;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.attributes = json.attributes;
        this.applicationStore = Application.loadArrayFromJson(json.datastores);
        this.applicationExchanges = DataExchange.loadArrayFromJson(json.dataexchanges);
        this.infrastructures = Infrastructure.loadArrayFromJson(json.infrastructures);
        this.diagrams = Diagram.loadArrayFromJson(json.diagrams);
        this.lastUpdate = json.lastUpdate;
        this.attachments = Attachment.loadArrayFromJson(json.attachments);
        this.category = json.category;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Data>;
            json.forEach(element => {
                ret.push(new Data(element));
            });
            return ret;
        }
    }


}