import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SimplebarAngularModule } from 'simplebar-angular';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SimpleLayoutComponent } from './simple-layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { MainService } from '../../core/services/main.service';
import { StatsService } from '../../core/services/stats.service';
import { ApiService } from '../../core/services/apiService';
import { SimpleContentComponent } from './simple-content/simple-content.component';
import { SwaggerComponent } from './swagger/swagger.component';
import { LucidApplicationsComponent } from './lucid/lucid-applications/lucid-applications.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    SimpleLayoutComponent,
    SimpleContentComponent,
    SwaggerComponent,
    LucidApplicationsComponent],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    UiSwitchModule
  ],
  providers: [
    MainService,
    StatsService,
    ApiService
  ]
})
export class SimpleLayoutsModule { }
