import { Architecture } from "./architecture";
import { Attachment } from "./attachment";
import { BusinessCapability } from "./businessCapability";
import { Diagram } from "./diagram";
import { Functionality } from "./functionality";
import { Infrastructure } from "./infrastructure";
import { Technology } from "./technology";
import { Process } from "./process";
import { Data } from "./data";
import { DataExchange } from "./dataExchange";
import moment from 'moment';
import { Cost } from "./cost";

export enum ApplicationType {
    saas = "Saas",
    paas = "PaaS",
    iaas = "IaaS",
    hybride = "Hybride",
    onPremise = "On Premise"
}

export class Application {

    public id: string;
    public name: string;
    public description: string;
    public code: string;
    public applicationType: ApplicationType;
    public technologies: Array<Technology>;

    public ass_bus: string;
    public ass_funcsup: string;
    public ass_techeff: string;
    public ass_techcomp: string;
    public ass_obs: string;
    public ass_eco: number;

    public businessCapabilities: Array<BusinessCapability>;
    public functionalities: Array<Functionality>;
    public components: Array<Architecture>;
    public infrastructures: Array<Infrastructure>;
    public attachments: Array<Attachment>;
    public diagrams: Array<Diagram>;
    public attributes: Array<{
        label: string,
        value: string,
    }>;
    public dataStore: Array<Data>;
    public lastUpdate: string;
    public processes: Array<Process>;
    public dataExchanges: Array<DataExchange>;

    public costs: Array<Cost>;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.code = json.code;
        this.setType(json.application_type);
        this.technologies = Technology.loadArrayFromJson(json.technologies);

        this.ass_bus = json.ass_bus;
        this.ass_funcsup = json.ass_funcsup;
        this.ass_techeff = json.ass_techeff;
        this.ass_techcomp = json.ass_techcomp;
        this.ass_obs = json.ass_obs;
        this.ass_eco = json.ass_eco;

        this.businessCapabilities = BusinessCapability.loadArrayFromJson(json.business_capabilities);
        this.functionalities = Functionality.loadArrayFromJson(json.functionalities);
        this.components = Architecture.loadArrayFromJson(json.components);
        this.infrastructures = Infrastructure.loadArrayFromJson(json.infrastructures);
        this.diagrams = Diagram.loadArrayFromJson(json.diagrams);
        this.attachments = Attachment.loadArrayFromJson(json.attachments);
        this.processes = Process.loadArrayFromJson(json.business_processes);
        this.dataStore = Data.loadArrayFromJson(json.datastores);
        this.dataExchanges = DataExchange.loadArrayFromJson(json.dataexchanges);

        this.attributes = json.attributes;
        this.lastUpdate = moment(json.last_update).format("DD MMM YYYY");

        this.costs = Cost.loadArrayFromJson(json.costs);
    }

    private setType(type: string) {
        switch (type) {
            case "saas":
                this.applicationType = ApplicationType.saas
                break;
            case "paas":
                this.applicationType = ApplicationType.paas
                break;
            case "iaas":
                this.applicationType = ApplicationType.iaas
                break;
            case "hybrid":
                this.applicationType = ApplicationType.hybride
                break;
            case "onpremise":
                this.applicationType = ApplicationType.onPremise
                break;

            default:
                break;
        }
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Application>;
            json.forEach(element => {
                ret.push(new Application(element));
            });
            return ret;
        }
    }

    public getAttachmentfilters() {
        try {
            let ret = new Array<string>();
            this.attachments.forEach(attach => {
                if (attach.fileType != null && !ret.includes(attach.fileType)) {
                    ret.push(attach.fileType)
                }
            });
            return ret;
        } catch (error) {
            return [];
        }
    }

    public getDataExchangeSources() {
        let ret = []
        this.dataExchanges.forEach(exchange => {
            if (exchange.appTarget.id == this.id) {
                ret.push(exchange)
            }
        });
        return ret;
    }

    public getDataExchangeTargets() {
        let ret = []
        this.dataExchanges.forEach(exchange => {
            if (exchange.appSource.id == this.id) {
                ret.push(exchange)
            }
        });
        return ret;
    }
}