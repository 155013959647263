export class Technology {

    public id: string;
    public name: string;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Technology>;
            json.forEach(element => {
                ret.push(new Technology(element));
            });
            return ret;
        }
    }
}