import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./simple-layout.component.scss']
})
export class SimpleLayoutComponent implements OnInit, AfterViewInit {


  // layout related config
  layoutType!: string;
  layoutMode!: string;
  layoutwidth!: string;
  layoutposition!: string;
  topbar!: string;
  sidebarcolor!: string;
  sidebarsize!: string;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }
}
