export class Attachment {

    public id: string;
    public name: string;
    public description: string;
    public uri: string;
    public uriType: string;
    public fileType: string;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.uri = json.uri;
        this.uriType = json.uriType;
        this.fileType = json.fileType;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Attachment>;
            json.forEach(element => {
                ret.push(new Attachment(element));
            });
            return ret;
        }
    }
}