import { Attachment } from "./attachment";
import { Section } from "./section";
import moment from 'moment';

export class Process {

    public id: string;
    public name: string;
    public code: string;
    public description: string;
    public objectives: string;
    public actors: string;
    public roles: string;
    public input: Process;
    public output: Process;
    public kpi: string;
    public systemUsed: Array<string>;
    public risk: string;

    public attributes: Array<{ key: string, value: string }>
    public children: Array<Process>;
    public sections: Array<Section>;
    public lastUpdate: string;
    public breadcrumb: Array<
        {
            id: string,
            name: string
        }>
    public attachments: Array<Attachment>;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.code = json.code;
        this.description = json.description;
        this.objectives = json.objectives
        this.actors = json.actors
        this.roles = json.roles
        this.input = json.input
        this.output = json.output
        this.kpi = json.kpi
        this.systemUsed = json.systemUsed
        this.risk = json.risk
    
        this.attributes = json.attributes;
        this.children = Process.loadArrayFromJson(json.children);
        this.sections = Section.loadArrayFromJson(json.sections);
        if (this.sections) {
            this.sections.sort(this.sortSections);
        }
        this.lastUpdate = moment(json.last_update).format("DD MMM YYYY");
        if(json.breadcrumb){
            this.breadcrumb = json.breadcrumb.reverse();
        }
        this.attachments = Attachment.loadArrayFromJson(json.attachments);
    }

    public static loadArrayFromJson(json) {
        if (json != null) {
            let ret = new Array<Process>();
            json.forEach(element => {
                ret.push(new Process(element));
            });
            return ret;
        }
        else{
            return null
        }
    }

    public getAttachmentfilters() {
        try {
            let ret = new Array<string>();
            this.attachments.forEach(attach => {
                if (attach.fileType != null && !ret.includes(attach.fileType)) {
                    ret.push(attach.fileType)
                }
            });
            return ret;
        } catch (error) {
            return [];
        }

    }

    private sortSections(a, b) {
        try {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }

            // a = b
            return 0;
        } catch (error) {
            return 0;
        }

    }
}