import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'comp-ai-selector ',
  templateUrl: './ai-selector.component.html',
  styleUrls: ['./ai-selector.component.scss']
})

export class AISelectorComponent {


  constructor(private router: Router){

  }

  public openAI(){
    this.router.navigate(["ai"])
  }

}
