
export enum CostType {
    opex = "OPEX",
    capex = "CAPEX"
}


export class Cost {

    public id: string;
    public amount: number;
    public costType: CostType;
    public name: string;
    public year: number;

    constructor(json) {
        this.id = json.id;
        this.amount = json.amount;
        this.costType = json.cost_type;
        this.name = json.name;
        this.year = json.year;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Cost>;
            json.forEach(element => {
                ret.push(new Cost(element));
            });
            return ret;
        }
    }
}