import { Application } from "./application";
import { Data } from "./data";

export class DataExchange {

    public id: string;
    public appSource: Application;
    public appTarget: Application;
    public data: Data;
    public frequency: string;
    public technology: string;

    constructor(json) {
        this.id = json.id;
        this.appSource = new Application(json.appSource);
        this.appTarget = new Application(json.appTarget);
        this.data = new Data(json.data);
        this.frequency = json.frequency
        this.technology = json.technology
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<DataExchange>;
            json.forEach(element => {
                ret.push(new DataExchange(element));
            });
            return ret;
        }
    }
}