export class Infrastructure {

    public id: string;
    public name: string;
    public description: string;
    public country: string;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.country = json.country;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Infrastructure>;
            json.forEach(element => {
                ret.push(new Infrastructure(element));
            });
            return ret;
        }
    }
}