import { Injectable } from '@angular/core';
import { ApiService } from './apiService';
import { Application } from '../../DTO/application';
import { Process } from '../../DTO/process';
import { User } from '../../DTO/user';
import { Data } from '../../DTO/data';
import { Message } from '../../DTO/message';
import { environment } from 'src/environments/environment';

@Injectable()
export class MainService {


  public types = [
    { label: "Process", value: "process" },
    { label: "Application", value: "application" },
    { label: "Data", value: "data" }
  ];

  // Paging
  public currentSkip = 0;
  public currentPage = 1;
  public sizingPage = 10;

  public currentDevise;
  public SAMLConnection;
  public currentConfig;


  constructor(private apiService: ApiService) {
  }


  public sendMessageAI(content: Array<Message>) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/chat";

      let dataToSend = JSON.parse(JSON.stringify(content));
      dataToSend = dataToSend.splice(0, content.length - 1);

      this.apiService.makePost(url, { chat: dataToSend }).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        });
    });
  }

  public getApplications(search) {
    return new Promise((resolve, reject) => {

      // BUILD URL 
      let url = environment.serverURL + "/application?limit=" + this.sizingPage + "&skip=" + this.currentSkip;
      if (search != null && search != "") {
        url += "&search=" + search;
      }

      this.apiService.makeGet(url).then((data: any) => {

        if (data) {
          let ret = Application.loadArrayFromJson(data.data);
          resolve({ apps: ret, total: data.total });
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }

  public getNbApplication() {
    return new Promise((resolve, reject) => {

      // BUILD URL 
      let url = environment.serverURL + "/application/count";

      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data.count);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }

  public getDataList(search) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/data?limit=" + this.sizingPage + "&skip=" + this.currentSkip;
      if (search) {
        url += "&search=" + search;
      }
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = Data.loadArrayFromJson(data.data);
          resolve({ data: ret, total: data.total });
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  public getProcessFirstLevel() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/proc/root";
      // url += "?limit=" + this.sizingPage + "&skip=" + this.currentSkip;
      // url += "&search=" + search;
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = Process.loadArrayFromJson(data);
          resolve({ processes: ret, total: data.total });
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  public getNbProcesses() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/proc/count";
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data.count);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  // public getNbData() {
  //   return new Promise((resolve, reject) => {
  //     let url = environment.serverURL + "/data/count";
  //     this.apiService.makeGet(url).then((data: any) => {
  //       if (data) {
  //         resolve(data.count);
  //       }
  //       else {
  //         resolve(null)
  //       }
  //     },
  //       (err) => {
  //         reject(null)
  //       })

  //   });

  // }

  // public getChildrenProcesses(processID: string) {
  //   return new Promise((resolve, reject) => {
  //     let url = environment.serverURL + "/proc/" + processID;
  //     this.apiService.makeGet(url).then((data: any) => {
  //       if (data) {
  //         let ret = Process.loadArrayFromJson(data);
  //         resolve(ret);
  //       }
  //       else {
  //         resolve(null)
  //       }
  //     },
  //       (err) => {
  //         reject(null)
  //       })

  //   });

  // }

  public searchProcess(search: string) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/proc?limit=" + this.sizingPage + "&skip=" + this.currentSkip + "&search=" + search;
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = Process.loadArrayFromJson(data.data);
          resolve({ processes: ret, total: data.total });

        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  public getApplication(applicationId) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/application/" + applicationId;

      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = new Application(data);
          resolve(ret);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  public getData(dataID) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/data/" + dataID;

      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = new Data(data);
          resolve(ret);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  public getProcess(processID) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/proc/" + processID;
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = new Process(data);
          resolve(ret);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  public getChildrenProcesses(processID) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/proc/" + processID;
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = Process.loadArrayFromJson(data.children);
          resolve(ret);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })

    });

  }

  public getUsers(search) {

    return new Promise((resolve, reject) => {
      // BUILD URL 
      let url = environment.serverURL + "/admin/org/user?perpage=" + this.sizingPage + "&page=" + this.currentSkip;
      if (search.search != null && search.search != "") {
        url += "&search=" + search.search;
      }
      if (search.admin == true) {
        url += "&role=admin";
      }
      if (search.license != null) {
        url += "&license=" + search.license.value;
      }
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let users = User.loadArrayFromJson(data.users);
          resolve({ users: users, total: data.total });
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
      // resolve({
      //   users: User.loadArrayFromJson([
      //     {
      //       id: "1",
      //       firstname: "Guillaume",
      //       lastname: "Adam",
      //       email: "adamguillaume.mx@gmail.com",
      //       admin: true,
      //       hasLicense: true
      //     },
      //     {
      //       id: "2",
      //       firstname: "Guillaume not admin",
      //       lastname: "Adam",
      //       email: "adamguillaume.mx@gmail.com",
      //       admin: false,
      //       hasLicense: false
      //     }
      //   ]),
      //   total: 2
      // })
    });

  }

  public addUser(data) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/user/invite";
      this.apiService.makePost(url, data).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
      // resolve(true)
    });
  }


  public getRemainingLicenses() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/org/license";
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });

  }

  public createSAMLConnection(data) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/org/sso";
      this.apiService.makePost(url, data).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
      // resolve({ success: true })
    });

  }

  public getSAMLConnection() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/org/sso";
      this.apiService.makeGet(url).then((data: any) => {
        resolve(data);
      },
        (err) => {
          reject(null)
        })
    });

  }

  public getCompanyConfig() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/org/config";
      resolve({
        plan: ("ent"),
        db_current: "URLDEMO",
        db_cust: "URLCUSTOMER",
        db_demo: "URLDEMO",
        openai_key: undefined,
        openai_model: "gpt-3.5-turbo"
      })
      // this.apiService.makeGet(url).then((data: any) => {
      //     resolve(data);
      // },
      //   (err) => {
      //     reject(null)
      //   })
    });

  }

  public updateCompanyConfig(data) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/org/config";
      resolve({
        plan: ("ent"),
        db_current: "URLDEMO",
        db_cust: "URLCUSTOMER",
        db_demo: "URLDEMO",
        openai_key: undefined,
        openai_model: "gpt-3.5-turbo"
      })
      // let data = {
      //   openai_key: data.openai_key,
      //   openai_model: data.openai_model
      // }
      // this.apiService.makePatch(url, ).then((data: any) => {
      //     resolve(data);
      // },
      //   (err) => {
      //     reject(null)
      //   })
    });

  }

  public getOpenAIUrl() {
    return new Promise((resolve, reject) => {
      // let url = environment.serverURL + "/admin/org/sso";
      // this.apiService.makeGet(url).then((data: any) => {
      //     resolve(data);
      // },
      //   (err) => {
      //     reject(null)
      //   })
      resolve("https://TOTOTOTOOTOO")
    });

  }

  public editSAMLConnection(domains) {
    domains = domains.trim().replaceAll(" ", "");
    let data = {
      domains: domains.split(",")
    };
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/org/sso";
      this.apiService.makePatch(url, data).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
      // resolve({ success: true })
    });

  }

  public deleteSAMLConnection() {

    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/org/sso";
      this.apiService.makeDelete(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
      // resolve({})
    });

  }

  public updateUser(type: string, user: User, assign: boolean) {

    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/admin/user/" + user.user_id;
      let data;
      if (type == "hasLicense") {
        data = {
          hasLicense: assign
        }
      }
      if (type == "admin") {
        data = {
          userRole: assign == true ? "admin" : ""
        }
      }
      this.apiService.makePatch(url, data).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
      // resolve({ success: true })
    });

  }



  public getLabelAssessment(score: string) {
    let ret;
    switch (score) {
      case "poor":
        ret = "Poor"
        break;
      case "low":
        ret = "Low"
        break;
      case "medium":
        ret = "Medium"
        break;
      case "high":
        ret = "High"
        break;
      case "none":
        ret = "-"
        break;
      default:
        ret = null
        break;
    }
    return ret;
  }

  public resetPaging() {
    // RESET THE PAGING
    this.currentPage = 1;
    this.currentSkip = 0;
  }
}