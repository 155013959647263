
import { Architecture } from "./architecture";
import { Attachment } from "./attachment";
import { BusinessCapability } from "./businessCapability";
import { Diagram } from "./diagram";
import { Functionality } from "./functionality";
import { Infrastructure } from "./infrastructure";
import { Technology } from "./technology";
import { Process } from "./process";
import { Data } from "./data";
import { DataExchange } from "./dataExchange";


export class Section {

    public id: string;
    public title: string;
    public content: string;
    public proc: string;
    public order: number;

    constructor(json) {
        this.id = json.id;
        this.title = json.title;
        this.content = json.content;
        this.proc = json.proc;
        this.order = json.order;
    }

    public static loadArrayFromJson(json) {
        if (json) {
            let ret = new Array<Section>;
            json.forEach(element => {
                ret.push(new Section(element));
            });

            return ret;
        }
    }

}