import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { Application } from 'src/app/DTO/application';
import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-lucid-applications',
  templateUrl: './lucid-applications.component.html',
  styleUrls: ['./lucid-applications.component.scss']
})
export class LucidApplicationsComponent {

  // SEARCH INPUT MODEL
  public currentSearch;

  // LIST MODEL
  public applications: Array<Application>;

  // TOTAL MODEL
  public totalApplications: number;

  constructor(
    private mainService: MainService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
  }


  public ngOnInit() {


    this.authService.idTokenClaims$.subscribe(token => {
      console.log(token)
      parent.postMessage({
        token: token
      }, '*');
    })

    // BUILD THE CURRENT SEARCH OBJ
    this.buildCurrentSearchFromURL(this.route.snapshot.queryParams)

    // RETRIEVE THE APPLICATIONS
    this.getApplicationList(this.currentSearch);

  }

  private getApplicationList(search) {
    this.mainService.getApplications(search).then((data: { "apps": Array<Application>, "total": number }) => {
      if (data) {
        this.applications = data.apps;
        this.totalApplications = data.total;
      }
      else {
        // DISPLAY ERROR
        this.toastr.error("An error occured when retrieving the applications", "Error")
      }

    },
      err => {
        // DISPLAY ERROR
        this.toastr.error("Impossible to retrieve the applications", "Error")
      });
  }

  public keyDownOnInput(data) {

    // prevent a bug on ngbdropdown
    if (data.keyCode === 13) {
      event.preventDefault();

      // MAKE SEARCH
      this.updateURL();
    }
  }

  public paginationChanged(e) {

    // UPDATE THECURRENT PAGE OF THE APPLICATION
    if (e !== this.mainService.currentPage) {
      this.mainService.currentPage = e;

      if (e === 1) {
        this.mainService.currentSkip = 0;
      }
      this.mainService.currentSkip = ((e - 1) * this.mainService.sizingPage);

      // RETRIEVE THE APPLICATIONS
      this.getApplicationList(this.currentSearch);
    }
  }

  public updateURL() {
    let params = { search: "" };

    // CHECK IF A SEARCH HAS BEEN PUT
    if (this.currentSearch) {
      params.search = this.currentSearch;
    }

    // RESET THE PAGING
    this.mainService.resetPaging();

    // CALL THE NGIINIT FUNCTION TO RESET THE CURRENTSEARCH OBJ AND RETRIEVE APPLICATIONS
    this.router.navigate(["lucid/applications"], { queryParams: params }).then(done => {
      this.ngOnInit();
    })
  }

  private buildCurrentSearchFromURL(params) {
    // INITIALIZE currentSearch
    this.currentSearch = undefined;

    // CHECK IF URL PARAMS "SEARCH" IS PRESENT
    if (params.search) {
      // THEN PUT THE CURRENT SEARCH OBJ
      this.currentSearch = params.search;
    }
  }

  public ngOnDestroy() {
    this.mainService.resetPaging();
  }

  public selectApplication(app: Application) {
    parent.postMessage({
      id: app.id,
      name: app.name
    }, '*');
  }

}
