import { Injectable } from '@angular/core';
import { ApiService } from './apiService';
import { environment } from 'src/environments/environment';
import { BusinessCapability } from 'src/app/DTO/businessCapability';
import moment from 'moment';
import { Infrastructure } from 'src/app/DTO/infrastructure';

@Injectable()
export class StatsService {

  constructor(private apiService: ApiService) {
  }


  public getBusinessServiceStats(filters: any) {

    let url = environment.serverURL + "/dash/bc/map?itemtype=" + filters.type.value;
    if (filters.assessment) {
      url += "&asstype=" + filters.assessment.value
    }
    if (filters.assessmentValue) {
      url += "&assvalue=" + filters.assessmentValue.value
    }

    return new Promise((resolve, reject) => {
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          data.bcs.sort(this.alphabeticSort)
          
          data.bcs.forEach(bc => {
            bc.children.sort(this.alphabeticSort)  
            bc.children.forEach(subChildren => {
              subChildren.children.sort(this.alphabeticSort)  
            });
          });
          resolve(data.bcs);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        });
    });

  }

  public getBSMAtrix(bsID) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/dash/bc/" + bsID + "/matrix";
      this.apiService.makeGet(url).then((data: any) => {
        if (data.matrix) {
          data.matrix.apps = data.matrix.apps.sort(this.alphabeticSort)
          data.matrix.funcs = data.matrix.funcs.sort(this.alphabeticSort)
          resolve(data.matrix);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        });
    });

  }




  public getApplicationCostStats(filter) {
    return new Promise((resolve, reject) => {
      let endDate = moment().year() + 1;
      let startDate = endDate - 3;
      let url = environment.serverURL + "/dash/app/costs?size=10&start=" + startDate + "&end=" + endDate + "&costtype=" + (filter.capex == true ? "CAPEX" : "OPEX");

      // MANAGE THE FILTERS
      if (filter.businessCapabilityLvl3) {
        url += "&bc=" + filter.businessCapabilityLvl3
      }
      else if (filter.businessCapabilityLvl2) {
        url += "&bc=" + filter.businessCapabilityLvl2
      }
      else if (filter.businessCapabilityLvl1) {
        url += "&bc=" + filter.businessCapabilityLvl1
      }

      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });

  }

  public getAllBS() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/bc";
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = BusinessCapability.loadArrayFromJson(data.bcs);
          ret.sort(this.alphabeticSort)
          resolve(ret);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }



  public getTechnologyObsoStats() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/dash/tech";
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
      // let ret = {
      //   "total": 22,
      //   "eos_apps": {
      //     "today": [
      //       {
      //         "name": "APP 1",
      //         "id": "11",
      //         "date": "May 2022"
      //       }
      //     ],
      //     "6months": [
      //       {
      //         "name": "APP 2",
      //         "id": "12",
      //         "date": "September 2023"
      //       }
      //     ],
      //     "year": [
      //       {
      //         "name": "APP 3",
      //         "id": "13",
      //         "date": "February 2024"
      //       }
      //     ]
      //   },
      //   "eol_apps": {
      //     "today": [
      //       {
      //         "name": "APP 1",
      //         "id": "11",
      //         "date": "May 2022"
      //       }
      //     ],
      //     "6months": [
      //       {
      //         "name": "APP 2",
      //         "id": "12",
      //         "date": "September 2023"
      //       }
      //     ],
      //     "year": [
      //       {
      //         "name": "APP 3",
      //         "id": "13",
      //         "date": "February 2024"
      //       }
      //     ]
      //   },
      //   "mostUsed": [
      //     {
      //       "name": "techno A",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     },
      //     {
      //       "name": "techno B",
      //       "id": "11",
      //       "nbApp": 20
      //     }
      //   ]
      // }
      // resolve(ret);

    });

  }



  public getGreenITAverage() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/dash/eco/metrics";
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }

  public getGreenITList() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/dash/eco/top?size=10";
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }



  public getDataMostUsed(filters) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/dash/data/mostused"
      if (filters.category != null) {
        url += "?category=" + filters.category;
      }
      if (filters.category == null && filters.infra != null) {
        url += "?infras=" + filters.infra
      }
      if (filters.category != null && filters.infra != null) {
        url += "&infras=" + filters.infra;
      }

      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }

  public getDataCategories() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/data/category"
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          data.sort(this.alphabeticSort)
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }

  public getDataInfra() {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/infra"
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          let ret = Infrastructure.loadArrayFromJson(data).sort(this.alphabeticSort);
          resolve(ret);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }

  public getDataMap(filters) {
    return new Promise((resolve, reject) => {
      let url = environment.serverURL + "/dash/data/map"
      if (filters.category != null) {
        url += "?category=" + filters.category;
      }
      if (filters.category == null && filters.infra != null) {
        url += "?infras=" + filters.infra;
      }
      if (filters.category != null && filters.infra != null) {
        url += "&infras=" + filters.infra;
      }
      this.apiService.makeGet(url).then((data: any) => {
        if (data) {
          resolve(data);
        }
        else {
          resolve(null)
        }
      },
        (err) => {
          reject(null)
        })
    });
  }



  private alphabeticSort(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    // a = b
    return 0;
  }

}